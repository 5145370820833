import { clamp } from 'lodash';
import React, { useState } from 'react';

import { HuePointer } from './HuePointer';
import { Interaction, Interactive } from './Interactive';

interface Props {
  hue: number;
  onChange: (newHue: { h: number }) => void;
}

const HueBase = ({ hue, onChange }: Props) => {
  const [isMoving, setIsMoving] = useState(false);
  const handleMoveStart = () => {
    setIsMoving(true);
  };
  const handleMoveEnd = () => {
    setIsMoving(false);
  };
  const handleMove = (interaction: Interaction) => {
    onChange({ h: 360 * interaction.left });
  };

  const handleKey = (offset: Interaction) => {
    // Hue measured in degrees of the color circle ranging from 0 to 360
    onChange({
      h: clamp(hue + offset.left * 360, 0, 360),
    });
  };

  return (
    <div
      css={{
        borderRadius: 6,
        position: 'relative',
        height: 12,
        background:
          'linear-gradient(90deg,red 0,#ff0 17%,#0f0 33%,#0ff 50%,#00f 67%,#f0f 83%,red)',
        cursor: 'pointer',
      }}
    >
      <Interactive
        aria-label="Hue"
        aria-valuemax="360"
        aria-valuemin="0"
        aria-valuenow={Math.round(hue)}
        onKey={handleKey}
        onMove={handleMove}
        onMoveEnd={handleMoveEnd}
        onMoveStart={handleMoveStart}
      >
        <HuePointer
          left={hue / 360}
          style={{
            width: isMoving ? 12 : 8,
            height: isMoving ? 12 : 8,
            ':hover': {
              width: 12,
              height: 12,
            },
          }}
        />
      </Interactive>
    </div>
  );
};

export const Hue = React.memo(HueBase);
