import PencilIcon from '@duyank/icons/regular/Pencil';
import {
  EffectSettings,
  getTextEffectStyle,
  getTransformStyle,
} from '@lidojs/design-core';
import React, { useContext } from 'react';
import { EditorContext } from '../../editor/EditorContext';
import { useEditor } from '../../hooks';
import { TextLayerProps } from '../../layers';
import { EditorState, Layer } from '../../types';
import EditorContent from './EditorContent';

const TextEditor = () => {
  const { generateTextContent } = useContext(EditorContext);
  const { actions, activePage, textEditor, editorScale, layer } = useEditor(
    (state) => {
      const textEditor = state.textEditor as EditorState['textEditor'];
      const layer = state.pages[textEditor.pageIndex].layers[
        textEditor.layerId
      ] as unknown as Layer<TextLayerProps>;
      return {
        editorScale: state.scale,
        activePage: state.activePage,
        textEditor,
        layer,
      };
    }
  );
  if (!layer) {
    return null;
  }
  const editor = layer.data.editor;

  const {
    boxSize,
    position,
    scale,
    rotate,
    transparency,
    effect,
    colors,
    fontSizes,
    fonts,
  } = layer.data.props;

  const generateContent = async () => {
    const { text } = await generateTextContent(textEditor.editor.dom.innerText);
    const fontSize = fontSizes[0];
    const color = colors[0];
    const fontFamily = fonts[0].name;
    const textNode = textEditor.editor.state.schema.text(
      text,
      textEditor.editor.state.storedMarks
    );
    const paragraphNode = textEditor.editor.state.schema.node(
      'paragraph',
      {
        fontSize: `${fontSize}px`,
        color,
        fontFamily: fontFamily,
      },
      textNode,
      textEditor.editor.state.storedMarks
    );
    const tr = textEditor.editor.state.tr;
    tr.replaceWith(0, textEditor.editor.state.doc.content.size, paragraphNode);
    textEditor.editor.dispatch(tr);
  };
  return (
    <div
      css={{
        touchAction: 'pan-x pan-y pinch-zoom',
        pointerEvents: 'auto',
        position: 'absolute',
        width: boxSize.width * editorScale,
        height: boxSize.height * editorScale,
        transform: getTransformStyle({
          position: {
            x: position.x * editorScale,
            y: position.y * editorScale,
          },
          rotate,
        }),
        opacity: transparency,
        top: 0,
        left: 0,
      }}
    >
      <div
        css={{
          width: boxSize.width / scale,
          height: boxSize.height / scale,
          transform: `scale(${scale * editorScale})`,
          transformOrigin: '0 0',
          p: {
            '&:before': {
              ...getTextEffectStyle(
                effect?.name || 'none',
                effect?.settings as EffectSettings,
                colors[0],
                fontSizes[0]
              ),
            },
          },
          ...getTextEffectStyle(
            effect?.name || 'none',
            effect?.settings as EffectSettings,
            colors[0],
            fontSizes[0]
          ),
        }}
        onMouseDown={(e) => e.stopPropagation()}
      >
        {editor && <EditorContent editor={editor} />}
        <div
          css={{
            position: 'absolute',
            bottom: 4,
            right: 4,
            background: '#EBECF0',
            zIndex: 999,
            width: 20,
            height: 20,
            borderRadius: 9999,
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={generateContent}
        >
          <PencilIcon />
        </div>
      </div>
    </div>
  );
};

export default TextEditor;
