import { LayerComponentProps } from '@lidojs/design-core';
import { QRCodeSVG } from '@lidojs/qrcode';
import { FC, useMemo } from 'react';

export interface QrCodeContentProps extends LayerComponentProps {
  text: string;
  bgColor: string;
  textColor: string;
  logo?: string;
  scale: number;
}

export const QrCodeContent: FC<QrCodeContentProps> = ({
  text,
  logo,
  boxSize,
  bgColor,
  textColor,
  scale,
}) => {
  const svgData = useMemo(() => {
    return new QRCodeSVG(text, {
      width: boxSize.width / scale,
      height: boxSize.height / scale,
      level: 'H',
      bgColor: bgColor,
      fgColor: textColor,
      image: logo
        ? {
            source: logo,
            width: '20%',
            height: '20%',
            x: 'center',
            y: 'center',
          }
        : undefined,
    });
  }, [bgColor, boxSize.height, boxSize.width, logo, text, textColor]);
  return <div dangerouslySetInnerHTML={{ __html: svgData.toString() }} />;
};
