import { QrCodeContent, QrCodeContentProps } from '@lidojs/design-layers';
import React from 'react';
import { LayerComponent } from '../types';

export type QrCodeLayerProps = QrCodeContentProps;

const QrCodeLayer: LayerComponent<QrCodeLayerProps> = ({
  boxSize,
  scale,
  ...props
}) => {
  return (
    <div
      css={{
        transformOrigin: '0 0',
      }}
      style={{
        width: boxSize.width / scale,
        height: boxSize.height / scale,
        transform: `scale(${scale})`,
      }}
    >
      <QrCodeContent boxSize={boxSize} scale={scale} {...props} />
    </div>
  );
};

QrCodeLayer.info = {
  name: 'QrCode',
  type: 'ScaleOnly',
};
export default QrCodeLayer;
