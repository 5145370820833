import { updateImageCanvas } from '@lidojs/design-core';
import { ImageContent, ImageContentProps } from '@lidojs/design-layers';
import { reject } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useAsync } from 'react-use';
import { EditorContext } from '../editor/EditorContext';
import { useEditor, useLayer, useSelectedLayers } from '../hooks';
import { LayerComponent } from '../types';

export interface ImageLayerProps extends ImageContentProps {
  image: ImageContentProps['image'] & {
    thumb: string;
  };
}

const ImageLayer: LayerComponent<ImageLayerProps> = ({
  layerId,
  image,
  boxSize,
  position,
  rotate,
  ...props
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { actions, pageIndex, id } = useLayer();
  const { uploadImage } = useContext(EditorContext);
  const { selectedLayerIds } = useSelectedLayers();
  const { imageEditor, sidebar, isResizing, isDragging } = useEditor(
    (state) => ({
      imageEditor: state.imageEditor,
      sidebar: state.sidebar,
      isResizing: state.resizeData.status,
      isDragging: state.dragData.status,
    })
  );
  const [imageData, setImageData] = useState<ImageLayerProps['image']>({
    ...image,
    url: image.thumb,
  });
  const [isUploading, setIsUploading] = useState(false);
  const [imageSettings, setImageSettings] = useState<{
    brightness?: number | null;
    contrast?: number | null;
    saturation?: number | null;
    hueRotate?: number | null;
    grayscale?: number | null;
    blur?: number | null;
  }>({
    brightness: image.brightness || null,
    contrast: image.contrast || null,
    saturation: image.saturation || null,
    hueRotate: image.hueRotate || null,
    grayscale: image.grayscale || null,
    blur: image.blur || null,
  });
  const [imageEle, setImageEle] = useState<HTMLImageElement | null>(null);

  const showCanvas =
    image &&
    (imageSettings.brightness !== (image.brightness || null) ||
      imageSettings.contrast !== (image.contrast || null) ||
      imageSettings.saturation !== (image.saturation || null) ||
      imageSettings.hueRotate !== (image.hueRotate || null) ||
      imageSettings.grayscale !== (image.grayscale || null) ||
      imageSettings.blur !== (image.blur || null));

  useEffect(() => {
    if (!isDragging && !isResizing) {
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.onload = () => {
        setImageData((prevState) => ({ ...prevState, url: image.url }));
        setImageEle(img);
      };
      img.src = image.url;
    }
  }, [image.url, setImageData]);

  useEffect(() => {
    console.log(
      sidebar,
      isUploading,
      imageSettings,
      image,
      imageSettings.brightness !== (image.brightness || null) ||
        imageSettings.contrast !== (image.contrast || null) ||
        imageSettings.saturation !== (image.saturation || null) ||
        imageSettings.hueRotate !== (image.hueRotate || null) ||
        imageSettings.grayscale !== (image.grayscale || null) ||
        imageSettings.blur !== (image.blur || null)
    );
    if (
      !sidebar &&
      !isUploading &&
      (imageSettings.brightness !== (image.brightness || null) ||
        imageSettings.contrast !== (image.contrast || null) ||
        imageSettings.saturation !== (image.saturation || null) ||
        imageSettings.hueRotate !== (image.hueRotate || null) ||
        imageSettings.grayscale !== (image.grayscale || null) ||
        imageSettings.blur !== (image.blur || null))
    ) {
      setIsUploading(true);
      const data = {
        brightness: image.brightness || null,
        contrast: image.contrast || null,
        saturation: image.saturation || null,
        hueRotate: image.hueRotate || null,
        grayscale: image.grayscale || null,
        blur: image.blur || null,
      };
      const canvas = canvasRef.current as HTMLCanvasElement;
      canvas.toBlob(async (blob) => {
        const file = new File([blob as Blob], 'file.png', {
          type: 'image/png',
        });
        const { url, thumb } = await uploadImage(file);
        const img = new Image();
        img.src = url;
        img.crossOrigin = 'anonymous';
        img.onload = () => {
          // avoid flicker
          setImageSettings(data);
          setIsUploading(false);
          actions.setProp<ImageLayerProps>({
            image: {
              url,
              thumb,
              original: image.original ?? image.url,
            },
          });
        };
        img.onerror = (e) => {
          setIsUploading(false);
          reject('Cannot load image');
        };
      }, 'image/png');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    actions,
    image.blur,
    image.brightness,
    image.contrast,
    image.original,
    image.saturation,
    image.hueRotate,
    image.grayscale,
    image.url,
    imageSettings.blur,
    imageSettings.brightness,
    imageSettings.contrast,
    imageSettings.saturation,
    imageSettings.grayscale,
    imageSettings.hueRotate,
    isUploading,
    sidebar,
    uploadImage,
  ]);

  useAsync(async () => {
    if (canvasRef.current && !isResizing && !isDragging && imageEle) {
      await updateImageCanvas(
        canvasRef.current,
        image,
        imageEle,
        image.boxSize
      );
    }
  }, [isResizing, isDragging, image]);

  useEffect(() => {
    setImageData(image);
  }, [image]);
  return (
    <div
      css={{
        pointerEvents: 'auto',
        visibility:
          imageEditor &&
          imageEditor.pageIndex === pageIndex &&
          imageEditor.layerId === id
            ? 'hidden'
            : undefined,
      }}
      onDoubleClick={() =>
        selectedLayerIds.includes(id) &&
        actions.openImageEditor({
          position,
          rotate,
          boxSize,
          image,
        })
      }
    >
      <ImageContent
        boxSize={boxSize}
        image={imageData}
        layerId={layerId}
        position={position}
        rotate={rotate}
        {...props}
      >
        <img
          alt={image.url}
          css={{
            width: '100%',
            height: '100%',
            objectFit: 'fill',
            position: 'absolute',
            pointerEvents: 'none',
            visibility: showCanvas ? 'hidden' : 'visible',
          }}
          src={image.url}
        />
        <div
          css={{
            position: 'absolute',
            inset: 0,
            visibility: !showCanvas ? 'hidden' : 'visible',
          }}
        >
          <div css={{ width: '100%', height: '100%', pointerEvents: 'auto' }}>
            <canvas ref={canvasRef} />
          </div>
        </div>
      </ImageContent>
    </div>
  );
};

ImageLayer.info = {
  name: 'Image',
  type: 'Image',
};
export default ImageLayer;
