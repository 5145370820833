import QrCodeIcon from '@duyank/icons/regular/QrCode';
import React, { FC } from 'react';
import { useEditor } from '../hooks';
import { QrCodeLayerProps } from '../layers/QrCodeLayer';
import { Layer } from '../types';
import ColorSettings from './ColorSettings';
import SettingButton from './SettingButton';
import QrCodeConfigurationSidebar from './sidebar/QrCodeConfigurationSidebar';

interface QrCodeSettingsProps {
  layers: Layer<QrCodeLayerProps>[];
}

const QrCodeSettings: FC<QrCodeSettingsProps> = ({ layers }) => {
  const { actions, sidebar, activePage } = useEditor((state) => ({
    activePage: state.activePage,
    sidebar: state.sidebar,
  }));
  const layer = layers[0] as Layer<QrCodeLayerProps>;
  const { bgColor, textColor } = layer.data.props;

  const updateBgColor = (bgColor: string) => {
    actions.setProp<QrCodeLayerProps>(activePage, layer.id, {
      bgColor,
    });
  };

  const updateTextColor = (textColor: string) => {
    actions.setProp<QrCodeLayerProps>(activePage, layer.id, {
      textColor,
    });
  };
  const toggleQrCodeConfiguration = () => {
    if (sidebar === 'QRCODE_CONFIGURATION') {
      actions.setSidebar();
    } else {
      actions.setSidebar('QRCODE_CONFIGURATION');
    }
  };

  return (
    <div
      css={{
        display: 'grid',
        alignItems: 'center',
        gridAutoFlow: 'column',
        gridGap: 8,
      }}
    >
      <SettingButton onClick={toggleQrCodeConfiguration}>
        <span css={{ fontSize: 20 }}>
          <QrCodeIcon />
        </span>
        <span css={{ padding: '0 4px' }}>Edit</span>
      </SettingButton>
      <div
        css={{ height: 24, width: `1px`, background: 'rgba(57,76,96,.15)' }}
      />
      <ColorSettings
        colors={[bgColor]}
        params={{ bgColor: true }}
        onChange={updateBgColor}
      />
      <ColorSettings
        colors={[textColor]}
        params={{ textColor: true }}
        onChange={updateTextColor}
      />
      <div
        css={{ height: 24, width: `1px`, background: 'rgba(57,76,96,.15)' }}
      />
      {sidebar === 'QRCODE_CONFIGURATION' && (
        <QrCodeConfigurationSidebar open={true} />
      )}
    </div>
  );
};

export default QrCodeSettings;
