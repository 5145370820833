import {
  getGradientBackground,
  GradientStyle,
  LayerComponentProps,
} from '@lidojs/design-core';
import React, { FC, HTMLProps, PropsWithChildren } from 'react';
import { ImageContent, ImageContentProps } from './ImageContent';
import { VideoContent, VideoContentProps } from './VideoContent';

export interface RootContentProps
  extends LayerComponentProps,
    Omit<HTMLProps<HTMLDivElement>, 'color'> {
  color: string | null;
  gradientBackground: {
    colors: string[];
    style: GradientStyle;
  } | null;
  image?: (ImageContentProps['image'] & { transparency: number }) | null;
  video?: VideoContentProps['video'] | null;
}

export const RootContent: FC<PropsWithChildren<RootContentProps>> = ({
  boxSize,
  color,
  gradientBackground,
  image,
  video,
  position,
  rotate,
  layerId,
  children,
  ...props
}) => {
  return (
    <div
      css={{
        position: 'absolute',
        overflow: 'hidden',
        pointerEvents: 'auto',
        width: boxSize.width,
        height: boxSize.height,
      }}
      {...props}
    >
      {children ?? (
        <>
          <div
            css={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: boxSize.width,
              height: boxSize.height,
              background: gradientBackground
                ? getGradientBackground(
                    gradientBackground.colors,
                    gradientBackground.style
                  )
                : color || '#fff',
            }}
          />
          {image && (
            <ImageContent
              boxSize={boxSize}
              image={image}
              layerId={layerId}
              position={position}
              rotate={rotate}
            />
          )}
          {video && (
            <div css={{ width: boxSize.width, height: boxSize.height }}>
              <VideoContent
                boxSize={boxSize}
                layerId={layerId}
                position={position}
                rotate={rotate}
                video={video}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};
